// src/components/IntroductionPage.js
import React from 'react';
import '../styles/IntroductionPage.css';

const IntroductionPage = ({ onExploreGrammar, onExploreTranslate }) => {
  return (
    <div className="introduction-container">
      <div className="intro-background">
        <div className="intro-content">
            <img src={`${process.env.PUBLIC_URL}/main_icon.png`} alt="JapNative Icon" className="page-icon" />
            <h1>Welcome to JA語Native</h1>
            <p>Master Japanese with our AI-driven tools. Correct grammar, translate effortlessly, and enhance your Japanese communication skills.</p>
            <div className="model-info">
            <h2>Discover Our Language Models</h2>
            <p>
              Enjoy free access to <span className="highlight">Google</span> and <span className="highlight">Cohere</span> models for grammar correction. 
              <strong>Log in</strong> to unlock premium models like <span className="highlight">OpenAI</span> and <span className="highlight">Claude AI</span> for advanced experiences.
            </p>
            <p>
              For translations, we rely on <span className="highlight">DeepL</span>, one of the top-rated models for Japanese, ensuring <strong>high-quality</strong> and <strong>accurate results</strong>.
            </p>
          </div>
          <button className="explore-button" onClick={onExploreGrammar}>Explore Grammar Check</button>
          <button className="explore-button" onClick={onExploreTranslate}>Try Translation</button>
        </div>
      </div>
    </div>
  );
};

export default IntroductionPage;