import React, { useState, useEffect } from 'react';
import '../styles/Header.css';
import LoginPopup from './LoginPopup';

const Header = ({ onCheckClick, onAssistantClick }) => {
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState(''); // Thêm biến lưu tên người dùng

  // Kiểm tra token trong `localStorage` khi component được mount
  useEffect(() => {
    const token = localStorage.getItem('token');
    const storedUserName = localStorage.getItem('userName'); // Lưu tên người dùng trong localStorage
    if (token) {
      setIsLoggedIn(true);
      setUserName(storedUserName || "User"); // Lấy tên người dùng từ localStorage nếu có
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token'); // Xóa token khỏi localStorage
    localStorage.removeItem('userName'); // Xóa tên người dùng khỏi localStorage
    setIsLoggedIn(false); // Đặt trạng thái đăng nhập thành false
    setUserName(''); // Xóa tên người dùng
    alert("Bạn đã đăng xuất thành công!");
  };

  return (
    <header className="header">
      <div className="logo" onClick={() => window.location.reload()} style={{ cursor: 'pointer' }}>
        <div className="logo-icon">
          <img src={`${process.env.PUBLIC_URL}/main_icon.png`} alt="JapNative Icon" className="logo-image" />
        </div>
        <span className="logo-text">JA語Native</span>
      </div>
      <div className="header-buttons">
        <button className="assistant-button" onClick={onAssistantClick}>Assistant</button>
        <button className="check-button" onClick={onCheckClick}>Check Grammar</button>
        {/* Container cho Login và Logout */}
        <div className="login-container">
          <button
            className="login-button"
            onClick={() => setShowLoginPopup(true)}
          >
            {isLoggedIn ? userName : "Login"} {/* Hiển thị tên người dùng khi đã đăng nhập */}
          </button>
        </div>
      </div>
      
      {/* Hiển thị pop-up tùy vào trạng thái đăng nhập */}
      {showLoginPopup && (
        <LoginPopup
          onClose={() => setShowLoginPopup(false)}
          isLoggedIn={isLoggedIn}
          onLoginSuccess={(name) => {
            setIsLoggedIn(true);
            setUserName(name); // Lưu tên người dùng khi đăng nhập thành công
            localStorage.setItem('userName', name); // Lưu tên người dùng vào localStorage
          }}
          onLogout={handleLogout} // Truyền hàm đăng xuất
        />
      )}
    </header>
  );
};

export default Header;