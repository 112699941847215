import React, { useState } from 'react';
import axios from 'axios';
import '../styles/LoginPopup.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { API_URL } from '../config';

const LoginPopup = ({ onClose, isLoggedIn, onLoginSuccess, onLogout }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // Thêm state để hiển thị lỗi giữa màn hình

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleLogin = async () => {
    setErrorMessage('');
    if (!validateEmail(email)) {
      setErrorMessage('Email không hợp lệ. Vui lòng nhập đúng định dạng!');
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/api/auth/login`, { email, password });
      const token = response.data.token;
      localStorage.setItem('token', token);

      onLoginSuccess(email);
      onClose();

      toast.success("Đăng nhập thành công!", { position: "top-right", autoClose: 3000 });
      window.location.reload();
    } catch (error) {
      const errorMessage = error.response?.data?.message || "Đăng nhập thất bại. Vui lòng thử lại.";
      setErrorMessage(errorMessage);
    }
  };

  const handleRegister = async () => {
    setErrorMessage('');

    if (!validateEmail(email)) {
      setErrorMessage('Email không hợp lệ. Vui lòng nhập đúng định dạng!');
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage('Mật khẩu xác nhận không khớp!');
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/api/auth/register`, { email, password });
      const token = response.data.token;
      localStorage.setItem('token', token);

      onLoginSuccess(email);
      onClose();

      toast.success("Đăng ký thành công!", { position: "top-right", autoClose: 3000 });
      window.location.reload();
    } catch (error) {
      const errorMessage = error.response?.data?.message || "Đăng ký thất bại. Vui lòng thử lại.";
      setErrorMessage(errorMessage);
    }
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <button className="close-button" onClick={onClose}>✕</button>

        {isLoggedIn ? (
          <>
            <h2>Xin chào, {email}</h2>
            <button onClick={() => {
              localStorage.removeItem('token');
              onLogout();
              onClose();
            }}>Đăng xuất</button>
          </>
        ) : (
          <>
            <div className="login-header">
              <img src={`${process.env.PUBLIC_URL}/main_icon.png`} alt="JapNative Icon" className="logo-image" />
              <h2>{isRegistering ? 'Create Account 🎉' : 'Welcome Back 👋'}</h2>
              <p>{isRegistering ? 'Join us by creating an account.' : 'Let’s explore the app again with us.'}</p>
            </div>

            <div className="login-form">
              <label>Email</label>
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label>Password</label>
              <input
                type="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              {isRegistering && (
                <>
                  <label>Confirm Password</label>
                  <input
                    type="password"
                    placeholder="Confirm your password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </>
              )}

              {isRegistering ? (
                <button className="popup-login-button" onClick={handleRegister}>Register</button>
              ) : (
                <button className="popup-login-button" onClick={handleLogin}>Login</button>
              )}
            </div>

            <div className="register">
              {isRegistering ? (
                <>
                  Already have an account? <span onClick={() => setIsRegistering(false)} className="toggle-link">Login here</span>
                </>
              ) : (
                <>
                  Don’t have an account? <span onClick={() => setIsRegistering(true)} className="toggle-link">Register here</span>
                </>
              )}
            </div>
          </>
        )}
      </div>

      {/* Hiển thị lỗi ở giữa màn hình */}
      {errorMessage && (
        <div className="error-popup">
          <p>{errorMessage}</p>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default LoginPopup;