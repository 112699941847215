// src/components/TranslateFile.js
import React, { useState } from "react";
import axios from "axios";
import "../styles/TranslateFile.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';


const TranslateFile = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isTranslating, setIsTranslating] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [targetLang, setTargetLang] = useState("JA");

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    addFiles(files);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const files = Array.from(event.dataTransfer.files);
    addFiles(files);
  };

  const addFiles = (files) => {
    const filesWithStatus = files.map((file) => ({
        file,
        status: "pending",
        downloadUrl: null,
    }));
    setSelectedFiles((prev) => [...prev, ...filesWithStatus]);
  };

  const handleTranslate = async () => {
    setIsTranslating(true);
    const updatedFiles = [...selectedFiles];

    for (const fileObj of updatedFiles) {
      const formData = new FormData();
      formData.append("file", fileObj.file);
      formData.append("targetLang", targetLang);

      try {
        fileObj.status = "loading";
        setSelectedFiles([...updatedFiles]);

        const response = await axios.post(
          "https://grammarwithai.onrender.com/api/translate/file",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        fileObj.status = "success";
        fileObj.downloadUrl = response.data.url;

        // Tự động tải file sau khi dịch
        const link = document.createElement("a");
        link.href = fileObj.downloadUrl;
        link.download = fileObj.file.name;
        document.body.appendChild(link); // Gắn link vào DOM
        link.click();
        document.body.removeChild(link); // Xóa link sau khi tải
      } catch (error) {
        console.error("Error translating file:", error);
        fileObj.status = "error";
      }
    }

    setSelectedFiles([...updatedFiles]);
    setIsTranslating(false);
  };

  return (
    <div className="translate-file">
      <div
        className={`upload-section ${isDragging ? "dragging" : ""}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={() => document.getElementById("fileInput").click()}
      >
        <input
            id="fileInput"
            type="file"
            multiple
            onChange={handleFileChange}
            style={{ display: "none" }}
        />
        <p>
          or <span className="choose-files">Choose Files</span>
        </p>
      </div>

      <div className="language-select-section">
        <label htmlFor="target-lang">Select Target Language:</label>
        <select
          id="target-lang"
          value={targetLang}
          onChange={(e) => setTargetLang(e.target.value)}
        >
              <option value="AR">Arabic</option>
    <option value="BG">Bulgarian</option>
    <option value="CS">Czech</option>
    <option value="DA">Danish</option>
    <option value="DE">German</option>
    <option value="EL">Greek</option>
    <option value="EN">English (unspecified)</option>
    <option value="EN-GB">English (British)</option>
    <option value="EN-US">English (American)</option>
    <option value="ES">Spanish</option>
    <option value="ET">Estonian</option>
    <option value="FI">Finnish</option>
    <option value="FR">French</option>
    <option value="HU">Hungarian</option>
    <option value="ID">Indonesian</option>
    <option value="IT">Italian</option>
    <option value="JA">Japanese</option>
    <option value="KO">Korean</option>
    <option value="LT">Lithuanian</option>
    <option value="LV">Latvian</option>
    <option value="NB">Norwegian Bokmål</option>
    <option value="NL">Dutch</option>
    <option value="PL">Polish</option>
    <option value="PT">Portuguese (unspecified)</option>
    <option value="PT-BR">Portuguese (Brazilian)</option>
    <option value="PT-PT">Portuguese (European)</option>
    <option value="RO">Romanian</option>
    <option value="RU">Russian</option>
    <option value="SK">Slovak</option>
    <option value="SL">Slovenian</option>
    <option value="SV">Swedish</option>
    <option value="TR">Turkish</option>
    <option value="UK">Ukrainian</option>
    <option value="ZH">Chinese (unspecified)</option>
    <option value="ZH-HANS">Chinese (Simplified)</option>
    <option value="ZH-HANT">Chinese (Traditional)</option>
        </select>
      </div>

      <div className="translate-button-section">
        <button onClick={handleTranslate} disabled={isTranslating}>
          {isTranslating ? "Translating..." : "Translate"}
        </button>
      </div>

      <div className="file-status-section">
        {selectedFiles.map((fileObj, index) => (
          <div key={index} className="file-status-item">
            <div className="file-info">
              {fileObj.status === "success" ? (
                <a
                  href={fileObj.downloadUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="file-name-link"
                >
                  {fileObj.file.name}
                </a>
              ) : (
                <span className="file-name">{fileObj.file.name}</span>
              )}
            </div>
            <div className="file-status">
            {fileObj.status === "pending" && <span>Pending</span>}
              {fileObj.status === "loading" && (
                <FontAwesomeIcon icon={faSpinner} className="loading-spinner" />
              )}
              {fileObj.status === "success" && (
                <FontAwesomeIcon icon={faCheckCircle} className="success-icon" />
              )}
              {fileObj.status === "error" && (
                <FontAwesomeIcon icon={faTimesCircle} className="error-icon" />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TranslateFile;