import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../config';
import ReactMarkdown from 'react-markdown';
import '../styles/SearchWord.css';
import LoginPopup from './LoginPopup';

const SearchWord = () => {
  const [word, setWord] = useState('');
  const [result, setResult] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [addSuccess, setAddSuccess] = useState(false);
  const [selectedModel, setSelectedModel] = useState('openai');
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!word) return;

    if (!isLoggedIn) {
      setShowLoginPopup(true);
      return;
    }

    setLoading(true);
    setError('');
    setResult({});
    setAddSuccess(false);
    try {
      const token = localStorage.getItem('token');
      const res = await axios.post(
        `${API_URL}/api/words/search`,
        { word },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'x-model': selectedModel,
          },
        }
      );
      setResult(res.data || {});
      console.log('API Response:', res.data);
    } catch (err) {
      console.error("Full error response:", err.response);
      if (err.response && err.response.status === 403) {
        setError('Session expired. Please login again.');
        setShowLoginPopup(true);
        localStorage.removeItem('token');
      } else if (err.response && err.response.status === 400) {
        setError('Bad Request: Please check the word you entered.');
      } else {
        setError('An error occurred while searching.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleAddToVocabulary = async () => {
    if (!result || Object.keys(result).length === 0) return;

    setAddSuccess(false);
    setError('');
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${API_URL}/api/words/save`,
        {
          word: result.word,
          meaning: result.meaning,
          examples: result.examples,
          tips: result.tips,
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setAddSuccess(true);
      console.log('Add to vocabulary success:', response.data);
    } catch (err) {
      console.error('Add to vocabulary error:', err.response);
      if (err.response && err.response.status === 403) {
        setError('Session expired. Please login again.');
        setShowLoginPopup(true);
        localStorage.removeItem('token');
      } else {
        setError('Failed to add the word to your vocabulary list.');
      }
    }
  };

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
    setShowLoginPopup(false);
  };

  return (
    <div className="search-word-container">
      <h2>Search Word</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Word</label>
          <input
            type="text"
            value={word}
            onChange={(e) => setWord(e.target.value)}
            placeholder="Enter a word..."
            required
          />
        </div>
        <select
          value={selectedModel}
          onChange={(e) => setSelectedModel(e.target.value)}
          className="model-select"
        >
          <option value="openai">OpenAI</option>
          <option value="google">Google</option>
        </select>
        <button className="button-search" type="submit" disabled={loading}>
          {loading ? 'Searching...' : 'Search'}
        </button>
      </form>

      {error && <p className="error-message">{error}</p>}

      {result && !error && Object.keys(result).length > 0 && (
        <div className="result">
          <h3>Kết quả tìm kiếm</h3>
          <div className="result-item">
            <strong>Từ:</strong> {result.word}
          </div>
          <div className="result-item">
            <strong>Nghĩa là:</strong>
            <ReactMarkdown>{result.meaning}</ReactMarkdown>
          </div>
          <div className="result-item">
            <strong>Ví dụ:</strong>
            <ReactMarkdown>{result.examples}</ReactMarkdown>
          </div>
          <div className="result-item">
            <strong>Mẹo học:</strong>
            <ReactMarkdown>{result.tips}</ReactMarkdown>
          </div>
          <button
            className="add-to-vocabulary"
            onClick={handleAddToVocabulary}
            title="Add to vocabulary list"
          >
            ＋
          </button>
          {addSuccess && <p className="success-message">Word added to your vocabulary list!</p>}
        </div>
      )}

      {/* Popup Login */}
      {showLoginPopup && (
        <LoginPopup
          onClose={() => setShowLoginPopup(false)}
          onLoginSuccess={handleLoginSuccess}
        />
      )}
    </div>
  );
};

export default SearchWord;