import React, { useState, useEffect } from 'react';
import '../styles/GrammarChecker.css';
import axios from 'axios';
import { API_URL } from '../config';
import { marked } from 'marked'; 


const GrammarChecker = ({ initialPrompt, title }) => {
  const [text, setText] = useState('');
  const [correctedText, setCorrectedText] = useState('');
  const [loading, setLoading] = useState(false);
  const [service, setService] = useState('google');
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Thêm state để theo dõi trạng thái đăng nhập

  // Kiểm tra token trong localStorage
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true); // Nếu có token, người dùng đã đăng nhập
    }
  }, []);

  const handleCheckGrammar = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${API_URL}/api/grammar/check`,
        { 
          text,
          promptText: initialPrompt, 
        },
        {
          params: { service }
        }
      );
      setCorrectedText(response.data.correctedText);
    } catch (error) {
      console.error('Error checking grammar:', error);
    } finally {
      setLoading(false);
    }
  };

    const renderMarkdown = (markdownText) => {
      return { __html: marked(markdownText) };
    };

  return (
    <div className="grammar-checker-container">
      <h2>{title}</h2>
      <textarea
        onChange={(e) => setText(e.target.value)}
        placeholder="Nhập đoạn văn tiếng Nhật..."
      />
      <select className="model-select" value={service} onChange={(e) => setService(e.target.value)}>
        <option value="google">Google API</option>
        {/* Chỉ hiển thị tùy chọn OpenAI nếu người dùng đã đăng nhập */}
        {/* {isLoggedIn && <option value="openai">OpenAI API</option>}*/}
        <option value="openai">OpenAI API</option>
        <option value="cohere">Cohere API</option>
      </select>
      <button onClick={handleCheckGrammar} disabled={loading}>
        {loading ? 'Đang kiểm tra và chỉnh sửa...' : 'Submit'}
      </button>
      {loading && <p className="loading">Quây Ơ Mi Nịt...</p>}
      <div className="corrected-text">
        <p>Kết quả:</p>
        <div
          dangerouslySetInnerHTML={renderMarkdown(correctedText)} // Sử dụng render Markdown
        ></div>
      </div>
    </div>
  );
};

export default GrammarChecker;