// src/components/TranslateText.js
import React, { useState } from 'react';
import '../styles/TranslateText.css';
import { FaLanguage } from 'react-icons/fa';
import { API_URL } from '../config';

const TranslateText = () => {
    const [inputText, setInputText] = useState('');
    const [translatedText, setTranslatedText] = useState('');
    const [loading, setLoading] = useState(false);

    const handleTranslate = async () => {
    setLoading(true);
    try {
        const response = await fetch(`${API_URL}/api/translate/text`, {
        //const response = await fetch(`http://localhost:3100/api/text/translate`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text: inputText }),
    });
        const data = await response.json();
        setTranslatedText(data.translatedText.text); // Giả định `translatedText` là tên trường trả về từ API
    } catch (error) {
        console.error("Error translating text:", error);
        setTranslatedText("Translation failed. Please try again.");
    } finally {
        setLoading(false);
    }
};

return (
    <div className="translate-container">
    <div className="translate-section">
        <h2>English</h2>
        <textarea
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            placeholder="Type text to translate..."
        />
    </div>
    <div className="translate-button-section">
        <button onClick={handleTranslate} disabled={loading}>
            <FaLanguage className="translate-icon" /> {/* Icon dịch ngôn ngữ */}
            {loading ? 'Translating...' : 'Translate'}
        </button>
    </div>
    <div className="translate-section">
        <h2>Japanese</h2>
        <textarea
            value={translatedText}
            readOnly
            placeholder="Translation will appear here"
        />
        </div>
    </div>
    );
};

export default TranslateText;