import React, { useState } from 'react';
import '../styles/AssistantForm.css';
import axios from 'axios';
import { API_URL } from '../config'; // Đảm bảo bạn đã định nghĩa API_URL trong config.js
import { marked } from 'marked'; // Thêm thư viện marked để xử lý Markdown


const AssistantForm = () => {
  const [formData, setFormData] = useState({
    iam: 'Product Manager', // Giá trị mặc định cho "Tôi là"
    youAre: 'Trợ lý người Nhật của tôi', // Giá trị mặc định cho "Bạn là"
    youWill: '',
    baseOn: '',
    supportType: 'Slackでの返信',
  });
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [service, setService] = useState('google'); // Thêm state để chọn service

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const currentService = service; // Đảm bảo lấy giá trị state hiện tại
      const res = await axios.post(
        `${API_URL}/api/assistant/support`,
        formData,
        {
          headers: { 'x-service': currentService }, // Gửi service qua header
        }
      );
      setResponse(res.data.result || 'No response from server.');
    } catch (error) {
      console.error('Error while processing the request:', error);
      setResponse('Error occurred while calling the API.');
    } finally {
      setLoading(false);
    }
  };

  const renderMarkdown = (markdownText) => {
    return { __html: marked(markdownText) };
  };

  return (
    <div className="assistant-container">
      <div className="assistant-form">
      <h1 className="ai-greeting">こんにちは、何かお手伝いできることはありますか？</h1>
        <form onSubmit={handleSubmit}>

        <div className="form-group">
            <label htmlFor="supportType">Support Type</label>
            <select
              id="supportType"
              name="supportType"
              value={formData.supportType}
              onChange={handleChange}
              required
            >
              <option value="Slackでの返信">Slackでの返信</option>
              <option value="メール返信">メール返信</option>
              <option value="テストケースの作成">テストケースの作成</option>
              <option value="要件の分析">要件の分析</option>
              <option value="ベトナム語翻訳">ベトナム語翻訳</option>
              <option value="日本語への翻訳">日本語への翻訳</option>
              <option value="詳細設計の作成">詳細設計の作成</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="youWill">Bạn hãy</label>
            <input
              id="youWill"
              type="text"
              name="youWill"
              placeholder="Điều tra và giải thích nghiệp vụ"
              value={formData.youWill}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="baseOn">Content</label>
            <textarea
              id="baseOn"
              name="baseOn"
              placeholder="年末調整仕組み"
              value={formData.baseOn}
              onChange={handleChange}
              required
            ></textarea>
          </div>

          <div className="form-group">
            <select
              id="model-select"
              className="model-select"
              value={service}
              onChange={(e) => setService(e.target.value)}
            >
              <option value="google">google</option>
              <option value="openai">OpenAI</option>
            </select>
            <button type="submit" disabled={loading}>
            {loading ? 'Processing...' : 'Submit'}
          </button>
          </div>
        </form>
      </div>
      <div className={`assistant-response ${loading ? "loading" : ""}`}>
        <h3>Assistant Response</h3>
        {loading ? (
          <div className="loading-spinner">
            <div className="spinner"></div>
          </div>
        ) : (
          <div
            className="markdown-output"
            dangerouslySetInnerHTML={renderMarkdown(response)} // Hiển thị Markdown đã chuyển đổi
          ></div>
        )}
      </div>
    </div>
  );
};

export default AssistantForm;